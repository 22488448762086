import React from 'react';
import './Navbar.css';



const About = () => {
    const cardsData = [
        { id: 1, imageUrl: 'hdfc-bank-logo.png' },
        { id: 2, imageUrl: 'Kotak.png' },
        { id: 3, imageUrl: 'Yes.png' },
        { id: 4, imageUrl: 'IDFC.png' },
        { id: 5, imageUrl: 'ABCAP.png' },
        { id: 6, imageUrl: 'Axis.png' },
        { id: 7, imageUrl: 'Bajaj.jpg' },
        { id: 8, imageUrl: 'ICICI.png' },
      ];
  return (
    <>
      <section className="page-header">
        <div className="page-header__bg" style={{ backgroundImage: 'url(page-header-bg-1-1.jpg)' }}>
        </div>
        {/* /.page-header__bg */}
        <div className="container">
          <ul className="thm-breadcrumb list-unstyled">
            <li><a href="index.html">Home</a></li>
            <li>/</li>
            <li><span>Our Services</span></li>
          </ul>
          {/* /.thm-breadcrumb list-unstyled */}
          <h2>Our Services</h2>
        </div>
        {/* /.container */}
      </section>
      <div className="container-fluid p-3" style={{ background: 'linear-gradient(to right, #f3f2f2, #ffffff, #f5f3f3)' }}>
        <div className="title text-center position-relative">
          <h1 className="d-inline-block gradient-text mt-5">Brand who Trust Us</h1>
          <div className="underline"></div>
        </div>
        <p class="mt-3 text-center">We are amongst the top 3 most-preferred channel partners for loans for India's widest network of Banks, NBFCs, and Fintech</p>
        <div className="container mt-5">
        <div className="row">
          {cardsData.map((card) => (
            <div key={card.id} className="col-md-3 mb-4">
              <div className="card h-100">
                <img src={card.imageUrl} className="card-img-top mx-auto mt-3" alt={`Image for ${card.title}`} style={{ width: '80%', height: 'auto' }} />
                <div className="card-body d-flex flex-column align-items-center">
       
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      </div>

    </>
  );
};

export default About;
