import React from 'react';
import { Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material';
import { Bar, Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';

const ReportCard = ({ title, progress, description }) => (
  <Card>
    <CardContent>
      <Typography variant="h6" style={{ marginBottom: 10 }}>
        {title}
      </Typography>
      <CircularProgress variant="determinate" value={progress} size={70} thickness={2} />
      <Typography variant="subtitle1" style={{ marginTop: 10 }}>
        {description}
      </Typography>
    </CardContent>
  </Card>
);

const HomeDas = () => {
  // Assume this is the data for different reports
  const reportsData = [
    { title: 'Total Applications', progress: 60, description: 'Total loan applications received.' },
    { title: 'Refund Requests', progress: 25, description: 'Refund requests submitted.' },
    { title: 'Contact Us', progress: 80, description: 'Contact requests received.' },
    { title: 'Pending Approvals', progress: 40, description: 'Applications pending approval.' },
  ];

  // Chart data
  const doughnutData = {
    labels: ['Applications', 'Refunds', 'Contact'],
    datasets: [
      {
        data: [60, 25, 80],
        backgroundColor: ['#3f51b5', '#f50057', '#00acc1'],
      },
    ],
  };

  const doughnutOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };

  const barChartData = {
    labels: ['Pending Approvals', 'Applications', 'Refunds', 'Contact'],
    datasets: [
      {
        label: 'Progress',
        data: [40, 60, 25, 80],
        backgroundColor: ['#ff9800', '#4caf50', '#f50057', '#00acc1'],
      },
    ],
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4" style={{ marginBottom: 20 }}>
            Dashboard Overview
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <ReportCard title={reportsData[0].title} progress={reportsData[0].progress} description={reportsData[0].description} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <ReportCard title={reportsData[1].title} progress={reportsData[1].progress} description={reportsData[1].description} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <ReportCard title={reportsData[2].title} progress={reportsData[2].progress} description={reportsData[2].description} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <ReportCard title={reportsData[3].title} progress={reportsData[3].progress} description={reportsData[3].description} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Card style={{ height: '100%' }}>
            <CardContent>
              <Typography variant="h6" style={{ marginBottom: 20 }}>
                Applications Overview
              </Typography>
              <div style={{ maxHeight: '400px', overflow: 'hidden' }}>
                <Doughnut data={doughnutData} options={doughnutOptions} />
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card style={{ height: '100%' }}>
            <CardContent>
              <Typography variant="h6" style={{ marginBottom: 20 }}>
                Progress Overview
              </Typography>
              <Bar data={barChartData} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default HomeDas;
