import React, { useState, useEffect } from 'react';

const EmiCalculator = () => {
    // State variables for form inputs and results
    const [loanAmount, setLoanAmount] = useState(50000);
    const [interestRate, setInterestRate] = useState(12);
    const [loanTenure, setLoanTenure] = useState(5);
    const [emiResult, setEmiResult] = useState(null);
    const [principalAmount, setPrincipalAmount] = useState(null);
    const [totalRepaymentAmount, setTotalRepaymentAmount] = useState(null);

    // Function to calculate EMI, principal amount, and total repayment amount
    const calculateEMI = () => {
        const principal = loanAmount;
        const monthlyInterestRate = interestRate / 1200; // Monthly interest rate
        const numberOfPayments = loanTenure * 12; // Total number of payments

        const emi =
            (principal * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numberOfPayments)) /
            (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);

        setEmiResult(emi.toFixed(2)); // Update emiResult state with the calculated result

        const principalAmount = principal;
        setPrincipalAmount(principalAmount.toFixed(2));

        const totalRepaymentAmount = emi * numberOfPayments;
        setTotalRepaymentAmount(totalRepaymentAmount.toFixed(2));
    };

    // Function to update loan amount display
    const updateLoanAmountDisplay = (value) => {
        setLoanAmount(value);
    };

    // Function to update interest rate display
    const updateInterestRateDisplay = (value) => {
        setInterestRate(value);
    };

    // Function to update loan tenure display
    const updateLoanTenureDisplay = (value) => {
        setLoanTenure(value);
    };

    // useEffect to run calculations when inputs change
    useEffect(() => {
        calculateEMI();
    }, [loanAmount, interestRate, loanTenure]);

    return (
        <div className="container-fluid calculator-container p-5">
            <div className="row">
                {/* Left side with EMI calculator form */}
                <div className="calculator-form col-md-6">
                    <form id="emiForm">
                    <label htmlFor="loanAmount" style={{ fontWeight: 'bold' }}>Loan Amount (INR)</label>
<div className="form-group">
    <input
        type="range"
        className="form-control-range"
        id="loanAmount"
        min="10000"
        max="10000000"
        step="1000"
        value={loanAmount}
        onChange={(e) => {
            updateLoanAmountDisplay(parseInt(e.target.value, 10));
        }}
        style={{ height: '10px' }} // You can adjust the height value as needed
    />
    <div id="loanAmountDisplay">Loan Amount: ₹{loanAmount}</div>
</div>


                        <div className="form-group">
                            <label htmlFor="interestRate">Annual Interest Rate (%)</label>
                            <input
                                type="range"
                                className="form-control-range"
                                id="interestRate"
                                min="4"
                                max="24"
                                step="0.1"
                                value={interestRate}
                                onChange={(e) => {
                                    updateInterestRateDisplay(parseFloat(e.target.value));
                                }}
                            />
                            <div id="interestRateDisplay">Interest Rate: {interestRate}%</div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="loanTenure">Loan Tenure (Years)</label>
                            <input
                                type="range"
                                className="form-control-range"
                                id="loanTenure"
                                min="1"
                                max="10"
                                value={loanTenure}
                                onChange={(e) => {
                                    updateLoanTenureDisplay(parseInt(e.target.value, 10));
                                }}
                            />
                            <div id="loanTenureDisplay">Loan Tenure: {loanTenure} Years</div>
                        </div>
                    </form>
                </div>

                {/* Right side to display EMI result as a card */}
                <div className="col-md-6">
                    <div className="card bg-warning">
                        <div className="card-body">
                            <h3 className="card-title">EMI RESULT</h3>
                            <div id="emiResult">
                                {emiResult ? (
                                    <div>
                                        <p>EMI: ₹{emiResult} per month</p>
                                        <p>Principal Amount: ₹{principalAmount}</p>
                                        <p>Total Repayment Amount: ₹{totalRepaymentAmount}</p>
                                    </div>
                                ) : (
                                    <p>Please fill out the form</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </div>

    );
};

export default EmiCalculator;
