import React, { useState } from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import LogoImage from '../image/fin.png'; // Replace with the path to your image
import './Navbar.css'; // Import a CSS file for styling
import ModalComponent from '../components/Modal'; // Import the Modal component
import { Link } from 'react-router-dom';

const NavigationBar = () => {
    const [showModal, setShowModal] = useState(false);

    const handleModalShow = () => {
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    return (
        <Navbar style={{ backgroundColor: '#971b4f' }} variant="dark" expand="lg">
            <div className='container'>
                {/* Logo Column */}
                <div className="navbar-logo">
                    <Navbar.Brand href="#home" className="logo-container">
                        <img
                            src={LogoImage}
                            alt="Logo"
                            height="60"
                            className="d-inline-block align-top heartbeat"
                        />
                    </Navbar.Brand>
                </div>

                {/* Navigation Links Column */}
                <div className="navbar-nav-container">
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
                        <Nav className="navbar-nav">
                            <Nav.Item>
                                <Link to="/" className='nav-link text-white'>Home</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="/about" className='nav-link text-white'>About</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="/contact" className='nav-link text-white'>Contact</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="/service" className='nav-link text-white'>Our Services</Link>
                            </Nav.Item>

                            {/* Add more Nav.Item as needed */}
                        </Nav>
                    </Navbar.Collapse>
                </div>

                {/* Login Button Column */}
                <div className="login-button-container">
                    <Navbar.Collapse className="justify-content-end">
                        <Button
                            className="glowing"
                            style={{ width: 200, height: 50 }}
                            variant="outline-light"
                            onClick={handleModalShow}
                        >
                            Apply Loan
                        </Button>
                    </Navbar.Collapse>
                </div>
            </div>

            {/* Modal component */}
            <ModalComponent show={showModal} handleClose={handleModalClose} />
        </Navbar>
    );
};

export default NavigationBar;
