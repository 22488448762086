import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { firestore } from '../components/FirebaseConfig';
import './Navbar.css';

const LoanApplicationModal = ({ show, handleClose }) => {
    const [loanData, setLoanData] = useState({
        LoanType: '',
        LoanAmount: '',
        name: '',
        Mobile: '',
        Email: '',
        Aadhar: '',
        Pan: '',
        State: '',
        City: '',
        PinCode: '',
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isThankYouModalVisible, setThankYouModalVisible] = useState(false);
    const [applicationNo, setApplicationNo] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLoanData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        // Basic form validation
        if (
            !loanData.LoanType ||
            !loanData.LoanAmount ||
            !loanData.name ||
            !loanData.Mobile ||
            !loanData.Email ||
            !loanData.Aadhar ||
            !loanData.Pan ||
            !loanData.State ||
            !loanData.City ||
            !loanData.PinCode
        ) {
            alert('Please fill in all the fields');
            return;
        }

        try {
            // Create a unique ID using name, mobile, and Aadhar (converted to uppercase)
            const uniqueId = btoa(`${loanData.name}${loanData.Mobile}${loanData.Aadhar}`).slice(0, 8).toUpperCase();

            // Insert data into Firestore
            const docRef = await addDoc(collection(firestore, 'loanApplications'), {
                ...loanData,
                status: 'pending', // Default status
                ApplicationNo: uniqueId,
                timestamp: serverTimestamp(), // Add a timestamp if needed
            });

            // Show the thank you modal
            setApplicationNo(uniqueId);
            setThankYouModalVisible(true);

            // Reset the form
            setLoanData({
                LoanType: '',
                LoanAmount: '',
                name: '',
                Mobile: '',
                Email: '',
                Aadhar: '',
                Pan: '',
                State: '',
                City: '',
                PinCode: '',
            });

            // Close the modal after successful submission
            handleClose();
        } catch (error) {
            console.error('Error adding document: ', error);
            // Handle error, show a message, etc.
        }
    };

    const handleThankYouModalClose = () => {
        setThankYouModalVisible(false);
    };

    return (
        <>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton className='bg-warning'>
                    <Modal.Title>Apply Loan</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="loanApplicationForm" onSubmit={handleFormSubmit} className="needs-validation p-1">
                        {/* Form fields here */}
                        <div className="row">
                            {/* Loan Type and Loan Amount in 2 columns */}
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="loanType">Loan Type</label>

                                    <select
                                        className="form-control"
                                        id="loanType"
                                        name="LoanType"
                                        value={loanData.LoanType}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="" disabled>
                                            Select Loan Type
                                        </option>
                                        <option value="personal">Personal Loan</option>
                                        <option value="home">Home Loan</option>
                                        <option value="auto">Auto Loan</option>
                                        <option value="business">Business Loan</option>
                                        <option value="education">Education Loan</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="amount">Loan Amount</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        name="LoanAmount"
                                        id="amount"
                                        value={loanData.LoanAmount}
                                        onChange={handleChange}
                                        placeholder="Enter loan amount"
                                        required
                                        pattern="^\d+$"
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Name and Mobile in 2 columns */}
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="fullName">Full Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        id="fullName"
                                        value={loanData.name}
                                        onChange={handleChange}

                                        placeholder="Enter your full name"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="mobileNumber">Mobile Number</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="mobileNumber"
                                        name="Mobile"
                                        maxLength={10}
                                        value={loanData.Mobile}
                                        onChange={handleChange}
                                        placeholder="Enter your mobile number"
                                        required
                                        pattern="^\d{10}$"
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Email, Aadhar, and PAN in 3 columns */}
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="email">Email address</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        value={loanData.Email}
                                        onChange={handleChange}
                                        placeholder="Enter your email"
                                        name="Email"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="aadharNumber">Aadhar Number</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="aadharNumber"
                                        name="Aadhar"

                                        value={loanData.Aadhar}
                                        onChange={handleChange}
                                        placeholder="Enter your Aadhar number"
                                        required
                                        pattern="^\d{12}$"
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="panNumber">PAN Number</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="panNumber"
                                        name="Pan"
                                        value={loanData.Pan}
                                        onChange={handleChange}
                                        placeholder="Enter your PAN number"
                                        required
                                        pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}"
                                    />
                                </div>
                            </div>
                        </div>

                        {/* State, City, and PIN Code in 3 columns */}
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="state">State</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="state"
                                        placeholder="Enter your state"
                                        name="State"
                                        value={loanData.State}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="city">City</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="city"
                                        name="City"
                                        placeholder="Enter your city"
                                        value={loanData.City}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="pinCode">PIN Code</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="PinCode"
                                        id="pinCode"
                                        placeholder="Enter your PIN code"
                                        value={loanData.PinCode}
                                        onChange={handleChange}
                                        required
                                        pattern="^\d{6}$"
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Submit Button */}
                        <div className="form-group mt-5">
                            <button type="submit" className="glossy-button" disabled={isSubmitting}>
                                Submit Application
                            </button>
                        </div>

                        <div id="loadingSpinner" className="spinner-border text-primary" role="status" style={{ display: isSubmitting ? 'block' : 'none' }}>
                            <span className="sr-only">Loading...</span>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            {/* Thank You Modal */}
            <Modal show={isThankYouModalVisible} onHide={handleThankYouModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Thank You!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Your application has been submitted successfully.</p>
                    <p>Your Application No is: {applicationNo}</p>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default LoanApplicationModal;