import React from 'react';
import { Link } from 'react-router-dom';
import { Nav } from 'react-bootstrap';

const Footer = () => {
    return (
        <footer id="footer" style={{ marginTop: 0 }}>
            <div className="container-fluid">
                <div className="row">

                    {/* Column 1: Logo and Company Details */}
                    <div className="col-md-3 footer-column">
                        <div className="logo-footer">
                            <a className="navbar-brand me-0" href="index.html" title="Axis Fincorp" rel="home">
                                <img alt="Axis Fincorp" src="fin.png" className="img-fluid" />
                            </a>
                        </div>
                        <p className="mt-3">We value your time, and our streamlined processes reflect that. Axis Fincorp ensures quick and convenient loan approvals, allowing you to swiftly navigate through your financial milestones. Our commitment to efficiency is geared towards making your borrowing experience as smooth as possible.</p>

                        {/* Social Icons */}
                        <div className="footer-social social-icons">
                            <ul className="list-inline">
                                <li className="list-inline-item">
                                    <a href="your_facebook_link">
                                        <img src="facebook.png" alt="Facebook" />
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="your_twitter_link">
                                        <img src="twitter.png" alt="Twitter" />
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="your_youtube_link">
                                        <img src="youtube.png" alt="YouTube" />
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="your_instagram_link">
                                        <img src="instagram.png" alt="Instagram" />
                                    </a>
                                </li>
                                {/* Add more social icons as needed */}
                            </ul>
                        </div>
                    </div>

                    {/* Column 2: About Us */}
                    <div className="col-md-3 footer-column" style={{ marginTop: 70 }}>
                        <h3>Explore</h3>
                        <nav>
                            <ul className="list-unstyled">
                                <li>  <Nav.Item>
                                    <Link to="/about" className='nav-link text-white'>About</Link>
                                </Nav.Item></li>
                                <li><a  className="text-white">How it works</a></li>
                                <li><a  className="text-white">Loan Eligibility</a></li>
                                <li><a  className="text-white">Loan Calculator</a></li>
                                <li><a  className="text-white">Contact</a></li>
                                <li>   <Nav.Item>
                                    <Link to="/admin-login" className='nav-link text-white'>Admin</Link>
                                </Nav.Item></li>

                                {/* Add more about us links as needed */}
                            </ul>
                        </nav>
                    </div>

                    {/* Column 3: Resources and Financial Tools */}
                    <div className="col-md-3 footer-column" style={{ marginTop: 70 }}>
                        <h3>Help & Support</h3>
                        <nav>
                            <ul className="list-unstyled">
                                <li><a  className="text-white">Privacy Policy</a></li>
                                <li><a  className="text-white">Terms & Conditions</a></li>
                                <li><a  className="text-white">Refund & Cancellation</a></li>
                                <li><a  className="text-white">Disclaimer</a></li>
                                <li>   <Nav.Item>
                                <Link to="/refund" className='nav-link text-white'>Refund</Link>
                            </Nav.Item></li>
                                <li><a  className="text-white">FAQ</a></li>
                                {/* Add more resource and financial tool links as needed */}
                            </ul>
                        </nav>
                    </div>

                    {/* Column 4: Contact Example */}
                    <div className="col-md-3 footer-column" style={{ marginTop: 70 }}>
                        <h3>Contact Us</h3>
                        <p>Email: customercare@axisfincorp.com</p>
                        <p>Phone: +91  9717493282</p>
                        <p>Address: Plot no. 305 Jessore Rd, saket new dehi - 110017</p>
                    </div>

                </div>
            </div>
        </footer>
    );
};

export default Footer;
