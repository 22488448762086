// FirebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';


const firebaseConfig = {
  apiKey: "AIzaSyAGpZUVjIInx7QdQsoCzyoSiUUSiGCrH1k",
  authDomain: "axisfincorp-44ed5.firebaseapp.com",
  projectId: "axisfincorp-44ed5",
  storageBucket: "axisfincorp-44ed5.appspot.com",
  messagingSenderId: "648304219316",
  appId: "1:648304219316:web:3d4556a73d4ff77d88b3e1",
  measurementId: "G-93E64PF5MH"
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth(app);  // Create and export the auth object

export { firestore, auth };
