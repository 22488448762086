import React from 'react';
import { Carousel } from 'react-bootstrap';
import './Navbar.css';
import EmiCalculator from './Emi';
import TrackApplication from './TrackApplication';



const CarouselComponent = () => {
  



  return (
    <>
      <Carousel className='container'>
        <Carousel.Item>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6" style={{ marginTop: '130px' }}>
                <h2>Get the loan you need to make your dreams a reality.</h2>
                <p>Your trusted partner in financial solutions. Explore our range of services and secure your financial
                  future with Axis Fincorp.</p>
                <div className="mt-2">
                  <button className="btn btn-primary">Learn More</button>
                </div>
              </div>
              <div className="col-md-6 p-5 image">
                <img src="img1.jpg" className="d-block w-100" alt="Image 1" />
              </div>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6" style={{ marginTop: '130px' }}>
                <h2>We offer competitive interest rates to help you save money on your loan.</h2>
                <p>Discover the story behind Axis Fincorp. We've been shaping the future of finance with innovative
                  solutions for over a decade.</p>
                <div className="mt-2">
                  <button className="btn btn-primary">Learn More</button>
                </div>
              </div>
              <div className="col-md-6 image p-5">
                <img src="img2.jpg" className="d-block w-100" alt="Image 2" />
              </div>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6" style={{ marginTop: '130px' }}>
                <h2>We have an experienced and reliable team committed to helping you with your loan needs.</h2>
                <p>Axis Fincorp offers a comprehensive range of financial services. From loans to investments, we've got
                  you covered.</p>
                <div className="mt-2">
                  <button className="btn btn-primary">Learn More</button>
                </div>
              </div>
              <div className="col-md-6 image p-5">
                <img src="img3.jpg" className="d-block w-100" alt="Image 3" />
              </div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
      {/* <!-- Tracking section --> */}
         <TrackApplication/>
      {/* <!-- Tracking section --> */}

    

      {/* <!-- Card --> */}
      <div className="container-fluid p-3" style={{ background: 'linear-gradient(to right, #f3f2f2, #ffffff, #f5f3f3)' }}>
        <div className="title text-center position-relative">
          <h4 className="d-inline-block gradient-text mt-5">WHO WE ARE</h4>
          <div className="underline"></div>
        </div>
        <div className="row p-5">
          {/* <!-- Left side with text --> */}
          <div className="col-md-6">
            <p className='mt-5'>
              Welcome to Axis Fincorp – your gateway to financial empowerment. We are not just a financial
              institution; we are your companions in the journey towards prosperity.
            </p>
            <p>
              At Axis Fincorp, we believe in providing more than just loans; we offer solutions that transform
              lives. Our commitment to innovation and reliability sets us apart. Whether you dream of owning a home, need
              funds for education, or wish to drive your dream car, we have tailored financial solutions to meet your
              aspirations.
            </p>
            <p>
              Join hands with us as we pave the way for a brighter financial future. Our team is dedicated to
              understanding your unique needs and providing personalized assistance every step of the way.
            </p>
            <button className="btn btn-primary">Learn More</button>
          </div>

          {/* <!-- Right side with hexagon-shaped image --> */}
          <div className="col-md-6 p-2 text-center">
            <div style={{ border: '5px solid #ffd700', borderRadius: '10px', overflow: 'hidden', display: 'inline-block' }}>
              <img src="who.jpg" alt="Description" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Card --> */}

      <div className="container-fluid p-3" style={{ background: 'linear-gradient(to right, #f3f2f2, #ffffff, #f5f3f3)' }}>
        <div className="title text-center position-relative">
          <h4 className="d-inline-block gradient-text mt-5">EMI CALCULATOR</h4>
          <div className="underline"></div>
        </div>
        <EmiCalculator />
      </div>

      <div className="container-fluid p-3" style={{ background: 'linear-gradient(to right, #f3f2f2, #ffffff, #f5f3f3)' }}>
        <div className="title text-center position-relative">
          <h4 className="d-inline-block gradient-text mt-5">OUR SERVICES</h4>
          <div className="underline"></div>
        </div>
        <div className="container-fluid p-5">
          <div className="row">
            <div className="col-md-3">
              <div className="card h-100">
                <img src="img1.jpg" className="card-img-top" alt="Service 1" />
                <div className="card-body">
                  <h5 className="card-title">Business Loan</h5>
                  <p className="card-text">Get the financial support your business needs with our Business Loan services. Our flexible terms and competitive rates are designed to help your business thrive. Explore opportunities for growth and expansion today!</p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card h-100">
                <img src="img2.jpg" className="card-img-top" alt="Service 2" />
                <div className="card-body">
                  <h5 className="card-title">Home Loan</h5>
                  <p className="card-text">Turn your dream home into a reality with our Home Loan options. Whether you're a first-time buyer or looking to upgrade, our affordable home financing solutions are tailored to meet your needs. Find the perfect loan for your dream home.</p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card h-100">
                <img src="img3.jpg" className="card-img-top" alt="Service 3" />
                <div className="card-body">
                  <h5 className="card-title">Personal Loan</h5>
                  <p className="card-text">Unleash the possibilities with our Personal Loan services. Whether it's for a special occasion, travel, or unexpected expenses, our personal loans offer quick and hassle-free solutions. Experience financial flexibility with us!</p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card h-100">
                <img src="who.jpg" className="card-img-top" alt="Service 4" />
                <div className="card-body">
                  <h5 className="card-title">Quick Loan</h5>
                  <p className="card-text">Need cash fast? Our Quick Loan services are designed for immediate financial needs. Experience a streamlined application process and quick approval. Get the funds you need when you need them. Your financial solution is just a click away!</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>


    </>
  );

};



export default CarouselComponent;
