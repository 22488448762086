import React, { useState } from 'react';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { firestore } from '../components/FirebaseConfig';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const validateForm = () => {
    const errors = {};
    // Add your validation logic here
    if (!formData.name.trim()) {
      errors.name = 'Name is required';
    }
    if (!formData.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email is not valid';
    }
    if (!formData.message.trim()) {
      errors.message = 'Message is required';
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitting(true);
      try {
        // Add data to Firestore
        const docRef = await addDoc(collection(firestore, 'Contact'), {
          ...formData,
          timestamp: serverTimestamp()
        });
        console.log('Document written with ID:', docRef.id);
  
        // Reset the form
        setFormData({
          name: '',
          email: '',
          message: ''
        });
  
        // Display a thank-you message using an alert
        alert('Thank you for submitting the form!');
  
      } catch (error) {
        console.error('Error adding document: ', error);
        // Handle error, show an error message, or enable the button again
      } finally {
        setIsSubmitting(false);
      }
    }
  };
  

  return (
    <>
      <section className="page-header">
        <div className="page-header__bg" style={{ backgroundImage: 'url(page-header-bg-1-1.jpg)' }}>
        </div>
        {/* /.page-header__bg */}
        <div className="container">
          <ul className="thm-breadcrumb list-unstyled">
            <li><a href="index.html">Home</a></li>
            <li>/</li>
            <li><span>Contact Us</span></li>
          </ul>
          {/* /.thm-breadcrumb list-unstyled */}
          <h2>Contact Us</h2>
        </div>
        {/* /.container */}
      </section>


      <div className="container-fluid p-3" style={{ background: 'linear-gradient(to right, #f3f2f2, #ffffff, #f5f3f3)' }}>
        <div className="title text-center position-relative">
          <h1 className="d-inline-block gradient-text mt-5">Contact US</h1>
          <div className="underline"></div>
        </div>
        <div className="container mt-5 p-5">
          <div className="row">
            {/* Column 1: Contact Details and Content */}
            <div className="col">
              <h3 className='mt-5'>Contact Details</h3>
              <p>Email: customercare@axisfincorp.com</p>
              <p>Phone: +91  9717493282</p>
              <p>Address: Plot no. 305 Jessore Rd, saket new dehi - 110017</p>

              {/* Additional content */}
              <h3 className='mt-5'>Why Contact Us</h3>
              <p>We are here to assist you with any inquiries you may have. Our dedicated team is committed to providing excellent service and addressing your needs promptly. Feel free to reach out to us for any information or assistance you require.</p>
            </div>



            {/* Column 2: Contact Form Card */}
            <div className="col">
              <div className="card">
                <div className="card-body">
                  <h3>Contact Us</h3>
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">Name</label>
                      <input
                        type="text"
                        className={`form-control ${formErrors.name ? 'is-invalid' : ''}`}
                        id="name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                      {formErrors.name && <div className="invalid-feedback">{formErrors.name}</div>}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">Email</label>
                      <input
                        type="email"
                        className={`form-control ${formErrors.email ? 'is-invalid' : ''}`}
                        id="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {formErrors.email && <div className="invalid-feedback">{formErrors.email}</div>}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="message" className="form-label">Message</label>
                      <textarea
                        className={`form-control ${formErrors.message ? 'is-invalid' : ''}`}
                        id="message"
                        rows="3"
                        value={formData.message}
                        onChange={handleChange}
                      ></textarea>
                      {formErrors.message && <div className="invalid-feedback">{formErrors.message}</div>}
                    </div>
                    <button type="submit" className="btn btn-primary w-100" disabled={isSubmitting}>
                      {isSubmitting ? 'Submitting...' : 'Submit'}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;