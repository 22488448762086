import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavigationBar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import AdminLogin from './components/AdminLogin';
import OurService from './components/OurService';
import Refund from './components/Refund';

import AuthenticatedAdminPanel from './components/AuthenticatedAdminPanel';
import Contact from './components/Contact';
import Footer from './components/Footer'; // Import the Footer component

function App() {
  return (
    <Router>
      <NavigationBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/service" element={<OurService/>} />
        <Route path="/admin-login" element={<AdminLogin />} />
        <Route path="/refund" element={<Refund />} />

        <Route path="/dashboard" element={<AuthenticatedAdminPanel />} />
      </Routes>
      <Footer /> {/* Include the Footer component at the end */}
    </Router>
  );
}

export default App;
