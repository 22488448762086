import React from 'react';
import './Navbar.css';



const About = () => {
  return (
    <>
      <section className="page-header">
        <div className="page-header__bg" style={{ backgroundImage: 'url(page-header-bg-1-1.jpg)' }}>
        </div>
        {/* /.page-header__bg */}
        <div className="container">
          <ul className="thm-breadcrumb list-unstyled">
            <li><a href="index.html">Home</a></li>
            <li>/</li>
            <li><span>About Us</span></li>
          </ul>
          {/* /.thm-breadcrumb list-unstyled */}
          <h2>About Us</h2>
        </div>
        {/* /.container */}
      </section>
      <div className="container-fluid p-3" style={{ background: 'linear-gradient(to right, #f3f2f2, #ffffff, #f5f3f3)' }}>
        <div className="title text-center position-relative">
          <h1 className="d-inline-block gradient-text mt-5">ABOUT US</h1>
          <div className="underline"></div>
        </div>
        <p class="mt-5">Flexible, adaptable, and adjustable plans to fit your needs.
          We are a trusted partner in your financial journey. With a deep commitment to empowering your
          aspirations, we provide a range of loan solutions designed to meet your unique needs. Our streamlined
          processes, competitive interest rates, and personalized guidance ensure that your financial goals become
          achievable milestones. Whether it’s securing your dream home, expanding your business, or owning that
          car you’ve always wanted, we stand by you every step of the way. Backed by expertise and a dedication to
          your success, we are– Your Path to Financial Growth.</p>

      </div>

    </>
  );
};

export default About;
