import React, { useEffect, useState } from 'react';
import { collection, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { signOut } from 'firebase/auth';
import { firestore, auth } from './FirebaseConfig';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const AdminPanel = () => {
  const [data, setData] = useState([]);
  const [editableStatus, setEditableStatus] = useState(Array(0).fill(false));
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const snapshot = await getDocs(collection(firestore, 'loanApplications'));
      const fetchedData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setData(fetchedData);
      setEditableStatus(Array(fetchedData.length).fill(false));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this entry?')) {
      try {
        const collectionRef = collection(firestore, 'loanApplications');
        const docRef = doc(collectionRef, id);
        await deleteDoc(docRef);
        fetchData();
      } catch (error) {
        console.error('Error deleting data:', error);
      }
    }
  };

  const handleUpdate = async (id, index) => {
    if (window.confirm('Are you sure you want to save changes?')) {
      try {
        const collectionRef = collection(firestore, 'loanApplications');
        const docRef = doc(collectionRef, id);

        await updateDoc(docRef, { status: editableStatus[index] });

        setEditableStatus((prevStatus) => {
          const newStatus = [...prevStatus];
          newStatus[index] = false;
          return newStatus;
        });

        fetchData();
      } catch (error) {
        console.error('Error updating data:', error);
      }
    }
  };

  const handleLogout = () => {
    if (window.confirm('Are you sure you want to log out?')) {
      signOut(auth)
        .then(() => {
          navigate('/admin-login');  // Use navigate here
        })
        .catch((error) => {
          console.error('Error signing out:', error);
        });
    }
  };

  useEffect(() => {
    // Trigger horizontal scroll after data is loaded
    const tableContainer = document.querySelector('.table-container');
    if (tableContainer) {
      tableContainer.scrollLeft = tableContainer.scrollWidth;
    }
  }, [data]);


  

  return (
    <div className="container-fluid">
    <h2>Application's</h2>
      {/* Updated Table */}
      <div className="table-container" style={{ overflowX: 'auto', maxWidth: '100%' }}>
        <table className="table table-bordered table-striped mt-4">
          <thead>
            <tr>
              <th style={{ whiteSpace: 'nowrap' }}>Sno</th>
              <th style={{ whiteSpace: 'nowrap' }}>Application No</th>
              <th style={{ whiteSpace: 'nowrap' }}>Loan Type</th>
              <th style={{ whiteSpace: 'nowrap' }}>Loan Amount</th>
              <th style={{ whiteSpace: 'nowrap' }}>Name</th>
              <th style={{ whiteSpace: 'nowrap' }}>Mobile</th>
              <th style={{ whiteSpace: 'nowrap' }}>Email</th>
              <th style={{ whiteSpace: 'nowrap' }}>Pan</th>
              <th style={{ whiteSpace: 'nowrap' }}>Aadhar</th>
              <th style={{ whiteSpace: 'nowrap' }}>State</th>
              <th style={{ whiteSpace: 'nowrap' }}>City</th>
              <th style={{ whiteSpace: 'nowrap' }}>Pin Code</th>
              <th style={{ whiteSpace: 'nowrap' }}>Status</th>
              <th style={{ whiteSpace: 'nowrap' }}>Time/Date</th>
              <th colSpan="2" style={{ whiteSpace: 'nowrap' }}>Action Button</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={item.id}>
                <td style={{ whiteSpace: 'nowrap' }}>{index + 1}</td>
                <td style={{ whiteSpace: 'nowrap' }}>{item.ApplicationNo}</td>
                <td style={{ whiteSpace: 'nowrap' }}>{item.LoanType}</td>
                <td style={{ whiteSpace: 'nowrap' }}>{item.LoanAmount}</td>
                <td style={{ whiteSpace: 'nowrap' }}>{item.name}</td>
                <td style={{ whiteSpace: 'nowrap' }}>{item.Mobile}</td>
                <td style={{ whiteSpace: 'nowrap' }}>{item.Email}</td>
                <td style={{ whiteSpace: 'nowrap' }}>{item.Pan}</td>
                <td style={{ whiteSpace: 'nowrap' }}>{item.Aadhar}</td>
                <td style={{ whiteSpace: 'nowrap' }}>{item.State}</td>
                <td style={{ whiteSpace: 'nowrap' }}>{item.City}</td>
                <td style={{ whiteSpace: 'nowrap' }}>{item.PinCode}</td>
              
                <td style={{ whiteSpace: 'nowrap' }}>
                  {editableStatus[index] ? (
                    <input
                      type="text"
                      value={editableStatus[index]}
                      onChange={(e) => {
                        const newStatus = [...editableStatus];
                        newStatus[index] = e.target.value;
                        setEditableStatus(newStatus);
                      }}
                    />
                  ) : (
                    item.status
                  )}
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>{item.dateTime}</td>
                <td colSpan="2" style={{ whiteSpace: 'nowrap' }}>
                  <button
                    className="btn btn-danger mr-2"
                    style={{ borderRadius: 0 }}
                    onClick={() => handleDelete(item.id)}
                  >
                    Delete
                  </button>
                  {!editableStatus[index] && (
                    <button
                      className="btn btn-primary"
                      style={{ borderRadius: 0 }}
                      onClick={() => {
                        const newStatus = [...editableStatus];
                        newStatus[index] = item.status;
                        setEditableStatus(newStatus);
                      }}
                    >
                      Edit
                    </button>
                  )}
                  {editableStatus[index] && (
                    <button
                      style={{ borderRadius: 0 }}
                      className="btn btn-success ml-2"
                      onClick={() => handleUpdate(item.id, index)}
                    >
                      Save
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminPanel;
