import React, { useState } from 'react';
import AdminPanel from './AdminPanel'; // Import the AdminPanel component
import './Navbar.css'; // Import the custom styles
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { Card } from 'react-bootstrap'; // Import Card component from Bootstrap
import { signOut } from 'firebase/auth'; // Import signOut function from Firebase auth
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { auth } from './FirebaseConfig';
import HomeDas from './HomeDas';
import ContactData from './ContactData';
import RefundData from './RefundData';

const Dashboard = () => {
  const [currentPage, setCurrentPage] = useState('home');
  const navigate = useNavigate(); // Use navigate from react-router-dom

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleLogout = async () => {
    try {
      // Sign out the user
      await signOut(auth);
      // Redirect to the admin login page
      navigate('/admin-login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        {/* Sidebar */}
        <nav id="sidebar" className="col-md-3 col-lg-2 d-md-block sidebar">
          <div className="position-sticky">
            <ul className="nav flex-column">
              <li className="nav-item">
                <Card className="nav-card" style={{ height: '80px', background: 'linear-gradient(to bottom, #ffd700, #666)' }}>
                  <Card.Body>
                    <button className={`nav-link ${currentPage === 'home' ? 'active' : ''}`} onClick={() => handlePageChange('home')}>Home</button>
                  </Card.Body>
                </Card>
              </li>
              <li className="nav-item">
                <Card className="nav-card" style={{ height: '80px', background: 'linear-gradient(to bottom, #ffd700, #666)' }}>
                  <Card.Body>
                    <button className={`nav-link ${currentPage === 'application' ? 'active' : ''}`} onClick={() => handlePageChange('application')}>Application</button>
                  </Card.Body>
                </Card>
              </li>
              <li className="nav-item">
                <Card className="nav-card" style={{ height: '80px', background: 'linear-gradient(to bottom, #ffd700, #666)' }}>
                  <Card.Body>
                    <button className={`nav-link ${currentPage === 'refund' ? 'active' : ''}`} onClick={() => handlePageChange('refund')}>Refund</button>
                  </Card.Body>
                </Card>
              </li>
              <li className="nav-item">
                <Card className="nav-card" style={{ height: '80px', background: 'linear-gradient(to bottom, #ffd700, #666)' }}>
                  <Card.Body>
                    <button className={`nav-link ${currentPage === 'contact' ? 'active' : ''}`} onClick={() => handlePageChange('contact')}>Contact</button>
                  </Card.Body>
                </Card>
              </li>
              <li className="nav-item">
                <Card className="nav-card" style={{ height: '80px', background: 'linear-gradient(to bottom, #ffd700, #666)' }}>
                  <Card.Body>
                    <button className="nav-link" onClick={handleLogout}>Logout</button>
                  </Card.Body>
                </Card>
              </li>
            </ul>
          </div>
        </nav>

        {/* Main Content */}
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <div className="pt-3">
            {currentPage === 'home' && (
                <HomeDas/>
             
            )}
            {currentPage === 'application' && (
              <AdminPanelCard />
            )}
            {currentPage === 'refund' && (
              <RefundData/>
             
            )}
            {currentPage === 'contact' && (
                <ContactData/>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

const AdminPanelCard = () => {
  return (
    <Card className="admin-panel-card" style={{ height: '90vh', boxShadow: '0 0 10px 5px #ccc', overflowY: 'auto' }}>
      <Card.Body>
        <AdminPanel />
      </Card.Body>
    </Card>
  );
};

export default Dashboard;
