import React, { useState } from 'react';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { firestore } from '../components/FirebaseConfig';

const Refund = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    applicationNo: '',
    amount: ''
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const validateForm = () => {
    const errors = {};
    // Add your validation logic here
    if (!formData.name.trim()) {
      errors.name = 'Name is required';
    }
    if (!formData.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email is not valid';
    }
    if (!formData.mobile.trim()) {
      errors.mobile = 'Mobile number is required';
    }
    if (!formData.applicationNo.trim()) {
      errors.applicationNo = 'Application number is required';
    }
    if (!formData.amount.trim()) {
      errors.amount = 'Amount is required';
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitting(true);
      try {
        // Add data to Firestore
        const docRef = await addDoc(collection(firestore, 'Refund'), {
          ...formData,
          timestamp: serverTimestamp()
        });
        console.log('Document written with ID:', docRef.id);

        // Reset the form
        setFormData({
          name: '',
          email: '',
          mobile: '',
          applicationNo: '',
          amount: ''
        });

        // Display a thank-you message using an alert
        alert('Refund application submitted. We will contact you soon!');

      } catch (error) {
        console.error('Error adding document: ', error);
        // Handle error, show an error message, or enable the button again
      } finally {
        setIsSubmitting(false);
      }
    }
  };
  return (
    <>
      <section className="page-header">
        <div className="page-header__bg" style={{ backgroundImage: 'url(page-header-bg-1-1.jpg)' }}>
        </div>
        {/* /.page-header__bg */}
        <div className="container">
          <ul className="thm-breadcrumb list-unstyled">
            <li><a href="index.html">Home</a></li>
            <li>/</li>
            <li><span>Refund</span></li>
          </ul>
          {/* /.thm-breadcrumb list-unstyled */}
          <h2>Refund</h2>
        </div>
        {/* /.container */}
      </section>
      <div className="container-fluid p-3" style={{ background: 'linear-gradient(to right, #f3f2f2, #ffffff, #f5f3f3)' }}>
        <div className="title text-center position-relative">
          <h1 className="d-inline-block gradient-text mt-5">Apply for Refund</h1>
          <div className="underline"></div>
        </div>
        <div className="container mt-5 p-5">
          <div className="row">
            {/* Column 1: Image */}
            <div className="col g-0">
            <div style={{ border: '30px solid #ffd700',borderRadius: '10px', overflow: 'hidden', display: 'inline-block' }}>
              <img src="ref.png" alt="Contact Image" style={{ width: '100%', }} />
              </div>
            </div>

            {/* Column 2: Contact Form Card */}
            <div className="col">
              <div className="card" style={{ background: 'linear-gradient(to right, #e5e4e2, #F5F5F5)' }}>
                <div className="card-body">
                  <h3>Refund Process..</h3>
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">Name</label>
                      <input
                        type="text"
                        className={`form-control ${formErrors.name ? 'is-invalid' : ''}`}
                        id="name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                      {formErrors.name && <div className="invalid-feedback">{formErrors.name}</div>}
                    </div>
                    <div className="row mb-3">
                      <div className="col">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input
                          type="email"
                          className={`form-control ${formErrors.email ? 'is-invalid' : ''}`}
                          id="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                        {formErrors.email && <div className="invalid-feedback">{formErrors.email}</div>}
                      </div>
                      <div className="col">
                        <label htmlFor="mobile" className="form-label">Mobile Number</label>
                        <input
                          type="text"
                          className={`form-control ${formErrors.mobile ? 'is-invalid' : ''}`}
                          id="mobile"
                          value={formData.mobile}
                          onChange={handleChange}
                        />
                        {formErrors.mobile && <div className="invalid-feedback">{formErrors.mobile}</div>}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col">
                        <label htmlFor="applicationNo" className="form-label">Application Number</label>
                        <input
                          type="text"
                          className={`form-control ${formErrors.applicationNo ? 'is-invalid' : ''}`}
                          id="applicationNo"
                          value={formData.applicationNo}
                          onChange={handleChange}
                        />
                        {formErrors.applicationNo && <div className="invalid-feedback">{formErrors.applicationNo}</div>}
                      </div>
                      <div className="col">
                        <label htmlFor="amount" className="form-label">Full Amount</label>
                        <input
                          type="text"
                          className={`form-control ${formErrors.amount ? 'is-invalid' : ''}`}
                          id="amount"
                          value={formData.amount}
                          onChange={handleChange}
                        />
                        {formErrors.amount && <div className="invalid-feedback">{formErrors.amount}</div>}
                      </div>
                    </div>
                    <button type="submit" className="btn btn-primary w-100" disabled={isSubmitting}>
                      {isSubmitting ? 'Submitting...' : 'Submit'}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Refund;