import React, { useState } from 'react';
import { getDocs, query, where, collection } from 'firebase/firestore';
import { firestore } from './FirebaseConfig'; // Import your Firebase configuration

const TrackApplication = () => {
  const [trackName, setTrackName] = useState('');
  const [applicationNumber, setApplicationNumber] = useState('');
  const [status, setStatus] = useState('');

  const handleTrackClick = async () => {
    // Validate if the name and application number are provided
    if (!trackName.trim() || !applicationNumber.trim()) {
      alert('Please enter both Name and Application Number');
      return;
    }

    // Create a query to search in the Firestore collection
    const q = query(
      collection(firestore, 'loanApplications'), // Replace 'loanApplications' with your collection name
      where('name', '==', trackName.trim()),
      where('ApplicationNo', '==', applicationNumber.trim())
    );

    try {
      // Execute the query
      const querySnapshot = await getDocs(q);

      // Check if any documents match the query
      if (querySnapshot.empty) {
        alert('No matching records found');
      } else {
        // Loop through the matching documents (there should be only one in this case)
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          // Access the status property from the data
          const applicationStatus = data.status || 'Status not available';
          setStatus(applicationStatus);
        });
      }
    } catch (error) {
      console.error('Error querying Firestore:', error);
    }
  };

  return (
    <div className="container-fluid mt-5 p-5" style={{ background: 'linear-gradient(to right, #3f1111, #e4b1b1)' }}>
      <div className="title text-center position-relative">
        <h4 className="d-inline-block gradient-text">TRACK YOUR APPLICATION</h4>
        <div className="underline"></div>
      </div>
      <div className="container mt-5">
        <div className="row justify-content-center g-0">
          <div className="col-4">
            <input
              type="text"
              className="form-control"
              id="trackName"
              placeholder="Name"
              style={{ height: 55, borderRadius: 0 }}
              onChange={(e) => setTrackName(e.target.value)}
            />
          </div>
          <div className="col-5">
            <input
              type="text"
              className="form-control"
              id="applicationNumber"
              style={{ height: 55, borderRadius: 0 }}
              placeholder="Application Number"
              onChange={(e) => setApplicationNumber(e.target.value)}
            />
          </div>
          <div className="col-3 text-white">
            <button
              type="button"
              className="btn bg-danger full-width"
              style={{ height: 55, width: 250, borderRadius: 0, fontSize: 20 }}
              onClick={handleTrackClick}
            >
              Track
            </button>
          </div>
          <div className="p-4">
            <h4 className="text-center text-white">{status}</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackApplication;
