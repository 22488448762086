import React, { useEffect, useState } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { firestore } from '../components/FirebaseConfig';

const RefundData = () => {
  const [refundData, setRefundData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'Refund'));
        const data = [];
        querySnapshot.forEach((doc) => {
          data.push({ id: doc.id, ...doc.data() });
        });
        setRefundData(data);
      } catch (error) {
        console.error('Error fetching refund data:', error);
      }
    };

    fetchData();
  }, []); // Empty dependency array to run the effect only once when the component mounts

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this refund record?');
    if (confirmDelete) {
      try {
        await deleteDoc(doc(firestore, 'Refund', id));
        setRefundData(refundData.filter((refund) => refund.id !== id));
      } catch (error) {
        console.error('Error deleting refund data:', error);
      }
    }
  };

  return (
    <div style={{ overflowY: 'auto', maxHeight: '100vh' }}>
      <h2>Refund Data</h2>
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Sno.</th>
            <th>Name</th>
            <th>Email</th>
            <th>Mobile Number</th>
            <th>Application Number</th>
            <th>Full Amount</th>
            <th>Timestamp</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {refundData.map((refund, index) => (
            <tr key={refund.id}>
              <td>{index + 1}</td>
              <td>{refund.name}</td>
              <td>{refund.email}</td>
              <td>{refund.mobile}</td>
              <td>{refund.applicationNo}</td>
              <td>{refund.amount}</td>
              <td>{refund.timestamp && refund.timestamp.toDate().toLocaleString()}</td>
              <td>
                <button
                  className="btn btn-danger"
                  onClick={() => handleDelete(refund.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RefundData;
